<template>
  <transition name="el-alert-fade">
    <div class="el-alert" :class="[typeClass, center ? 'is-center' : '', 'is-' + effect]" v-show="visible" role="alert">
      <svg-icon className="el-alert__icon" :iconClass="type" v-if="showIcon"></svg-icon>
      <div class="el-alert__content">
        <span class="el-alert__title" :class="[isBoldTitle]" v-if="title || $slots.title">
          <slot name="title">{{ title }}</slot>
        </span>
        <p class="el-alert__description" v-if="$slots.default && !description">
          <slot></slot>
        </p>
        <p class="el-alert__description" v-if="description && !$slots.default">{{ description }}</p>
        <i class="el-alert__closebtn" :class="{ 'is-customed': closeText !== '', 'el-icon-close': closeText === '' }"
          v-show="closable" @click="close()">{{ closeText }}</i>
      </div>
    </div>
  </transition>
</template>

<script>
// copy for element/packages/alert/src/main.vue
// 为了自定义icon
export default {
  name: 'StatusAlert',

  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    closeText: {
      type: String,
      default: '',
    },
    showIcon: Boolean,
    center: Boolean,
    effect: {
      type: String,
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].indexOf(value) !== -1
      },
    },
  },

  data() {
    return {
      visible: true,
    }
  },

  methods: {
    close() {
      this.visible = false
      this.$emit('close')
    },
  },

  computed: {
    typeClass() {
      return `el-alert--${this.type}`
    },

    isBoldTitle() {
      return this.description || this.$slots.default ? 'is-bold' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.el-alert {
  .el-alert__icon {
    align-self: flex-start;
  }

  &.is-light.el-alert--warning {
    border: 1px solid $warning;
    color: $warning;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--success {
    border: 1px solid $success;
    color: $success;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--error {
    border: 1px solid $danger;
    color: $danger;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--info {
    border: 1px solid $border-color-base;
    color: $info;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  .el-alert__closebtn {
    @include rtl-sass-prop(right, left, 15px);
    @include rtl-sass-prop(left, right, unset);
  }
}
</style>
