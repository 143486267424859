<template>
  <PageWrapper :tabs="tabs" :tabActive.sync="tabActive">
    <PagePanel>
      <el-select v-model="value1">
        <el-option>1</el-option>
        <el-option>2</el-option>
        <el-option>3</el-option>
        <template #prefix>11</template>
      </el-select>
      <StatusAlert
        title="成功提示的文案"
        description="文字说明文字说明文字说明文字说明文字说明文字说明"
        type="warning"
        show-icon
      ></StatusAlert>
      <StatusAlert title="成功提示的文案" type="warning" show-icon></StatusAlert>
      <!-- <el-alert title="成功提示的文案" type="info" show-icon> </el-alert> -->
      <AccountSelect></AccountSelect>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="日期" width="180"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
      </el-table>
      <SPagination @change="onPageChange" :current-page="currentPage4" :total="400"> </SPagination>
    </PagePanel>
    <PagePanel :tabs="tabs" :tabActive.sync="tabActive">
      <FundCard title="title" fee="100" time="7 * 24">
        <template slot="fee">fee</template>
        <template slot="time">time</template>
      </FundCard>
      <div slot="header" class="clearfix">
        <span>1234567890</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <el-date-picker v-model="value1" type="date" placeholder="选择日期"> </el-date-picker>
      <svg-icon iconClass="notice"></svg-icon>
      <el-button @click="drawerVisible = !drawerVisible">点击</el-button>
      <el-button @click="dialogVisible = !dialogVisible" type="primary">点击</el-button>
      <el-button @click="onMessage" type="primary" plain>点击</el-button>
      <span>1234567890</span>
      <el-select></el-select>
    </PagePanel>
    <PagePanel>
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <el-form-item label="名称">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="活动区域">
          <el-input v-model="formLabelAlign.region"></el-input>
        </el-form-item>
        <el-form-item label="活动形式">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
      </el-form>
    </PagePanel>
    <PagePanel>
      <el-button @click="onClick1">1</el-button>
      <el-button @click="onClick2">2</el-button>
      <el-button @click="onClick3">3</el-button>
      <el-button @click="onClick4">4</el-button>
    </PagePanel>
    <PagePanel>
      <div>
        <el-input placeholder="请输入内容">
          <template slot="prepend">Http://</template>
        </el-input>
      </div>
      <div style="margin-top: 15px;">
        <el-input placeholder="请输入内容">
          <template slot="append">.com</template>
        </el-input>
      </div>
      <div style="margin-top: 15px;"></div>
    </PagePanel>
    <PagePanel>
      <Empty>
        <div slot="description">asdf</div>
        <el-button>11</el-button>
      </Empty>
    </PagePanel>
    <el-tabs style="margin-top: 20px;" type="border-card" shadow="never">
      <el-tab-pane label="用户管理">用户管理</el-tab-pane>
      <el-tab-pane label="配置管理">配置管理</el-tab-pane>
      <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
    </el-tabs>
    <s-drawer title="抽屉" :helpMessage="'12312312312'" :visible.sync="drawerVisible" :disabledFooterConfirm="true">
      <el-button slot="centerFooter">中间</el-button>
      <el-button slot="insertFooter">insertFooter</el-button>
      <el-button slot="appendFooter">appendFooter</el-button>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
      <p>123</p>
    </s-drawer>
    <s-dialog :helpMessage="'12312312312'" :visible.sync="dialogVisible" :disabledFooterConfirm="true">
      <p>123</p>
    </s-dialog>
  </PageWrapper>
</template>

<script>
import StatusMessage from '@/components/customUI/StatusMessage.vue'
import StatusAlert from '@/components/customUI/StatusAlert.vue'
import FundCard from '@/components/FundCard.vue'

export default {
  name: 'Name',
  components: {
    StatusAlert,
    FundCard,
  },
  data() {
    return {
      value: '123',
      value1: '',
      drawerVisible: false,
      dialogVisible: false,
      tabs: [
        { name: 'first1', label: '用户管理1', dataTestId: 'first1' },
        { name: 'first2', label: '用户管理2', dataTestId: 'first2' },
        { name: 'first3', label: '用户管理3', dataTestId: 'first3' },
        { name: 'first4', label: '用户管理4', dataTestId: 'first4' },
        { name: 'first5', label: '用户管理5', dataTestId: 'first5' },
      ],
      tabActive: 'first1',
      formLabelAlign: {
        name: '',
        region: '',
        type: '',
      },
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄',
        },
      ],
      currentPage4: 4,
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    onMessage() {
      this.$message({
        message: '恭喜你，这是一条成功消息',
        type: 'success',
        duration: 0,
      })
    },
    onClick1() {
      this.$alert('这是一段内容', '标题名称', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`,
          })
        },
      })
    },
    onClick2() {
      const h = this.$createElement

      this.$msgbox({
        title: '消息',
        message: h(StatusMessage, { props: { title: '阿萨德发' } }),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            setTimeout(() => {
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 3000)
          } else {
            done()
          }
        },
      }).then(action => {
        this.$message({
          type: 'info',
          message: 'action: ' + action,
        })
      })
    },
    onClick3() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    onClick4() {
      this.$prompt('请输入邮箱', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: '邮箱格式不正确',
      })
        .then(({ value }) => {
          this.$message({
            type: 'success',
            message: '你的邮箱是: ' + value,
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
